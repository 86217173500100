import '../styles/globals.scss'
import '../styles/icons/uicons-regular-rounded.css'
import '../styles/icons/uicons-solid-rounded.css'
import { ChakraProvider, CSSReset, extendTheme, theme } from "@chakra-ui/react";
import type { AppProps /*, AppContext */ } from 'next/app'
import Head from "next/head";
import { AnimatePresence } from "framer-motion"
import { Box } from "@chakra-ui/react";
import { hostname } from '@aim/old_utils/helpers/helper';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import Script from "next/script";


// LIGHT COLORS
const brandLightBlue = '#E6F0FC'
const brandLightGray = '#8C959C'
const brandLightGrayBG = '#F8F8F8'
// DARK COLORS
const brandBlue = '#1631C7'
const brandBlack = '#454C64'
const brandGray = '#B0B0B0'

const border = '0.5px solid #E2E8F0'
const white = 'white'
const black = 'black'





const boxShadowHide = '0px 0px 0px 0px rgba(0,0,0,0.0)'
const boxShadowShow = '0px 0px 50px 2px rgba(0,0,0,0.25)'

const Link = {
    baseStyle: {
        color: brandLightGray,
        _hover: {
            color: brandBlue,
            textDecoration: 'none',
        }
    }
}


const Button = {
    variants: {
        ghost: {
            bg: brandLightGrayBG,
            color: brandBlack,
            border: '1px',
            borderColor: brandLightGrayBG,
            _active:{
                bg:'white',
                boxShadow:"md",
                borderColor: white
            },
            _hover: {
                bg:'white',
                boxShadow:"md",
                borderColor: white
            }
        },
        outline: {
            bg: brandLightGrayBG,
            color: brandLightGray,
            border,
            _hover:{
                bg: brandLightGrayBG,
                color: black,
                borderColor: white,
            }
        },
        solid: {
            bg: brandBlue,
            color: white,
            border,
            borderColor: brandBlue,
            boxShadow: boxShadowHide,
            _hover: {
                boxShadow: boxShadowShow,
                color: white,
                bg: brandBlue,
                border: "1px solid",
                borderColor: brandBlue,
            }
        },
    },
}

const Input = {
    parts: ['addons', 'field'],
    baseStyle: {
        field:{
            fontWeight:'bold',
            // background: 'white',
            _hover:{
                boxShadow: boxShadowShow,
                // background: 'red.500'
            },
            _focus:{
                boxShadow: boxShadowShow
            },
            focusBorderColor: 'red',
            border: '1px solid red',
            color: brandLightGray,
            borderColor: 'red',

        },
    },
}
const FormLabel = {
    baseStyle:{
        fontSize: 'xs',
        fontWeight:'bold',
        color: '#8C959C'
    }
}


// @ts-ignore
const customTheme = extendTheme({ 
    ...theme,
    colors:{
        ...theme.colors,
        brandLightGray,
        brandBlue,
        brandGray,
        brandLightBlue,
        brandBlack,
        brandLightGrayBG,
        blue:{
            100: '#1631C7',
            200: '#1631C7',
            300: '#1631C7',
            400: '#1631C7',
            500: '#1631C7',
        },
        grey: {
            50: "#f0f0fa",
            100: "#d1d3e1",
            200: "#b3b6c9",
            300: "#9499b4",
            400: "#777c9f",
            500: "#5d6385",
            600: "#484d69",
            700: "#34374b",
            800: "#1e212e",
            900: "#080b14",
        },
        red: {
            '50': '#fcf3f3', 
            '100': '#f9e8e8', 
            '200': '#f1c5c5', 
            '300': '#e9a2a2', 
            '400': '#d85c5c', 
            '500': '#C71616', 
            '600': '#b31414', 
            '700': '#951111', 
            '800': '#770d0d', 
            '900': '#620b0b'
        }
    },
    components: {
        FormLabel,
        // @ts-ignore
        Input,
        Button,
        Link,
        DrawerContent:{
            baseStyle: {
                borderRadius: "md", // Normally, it is "semibold"
              },
        },
        Drawer:{
            baseStyle: {
                borderRadius: "md", // Normally, it is "semibold"
              },
        }
    },
})


// Sentry.init({
//     dsn: "https://583782b9ba1041e9aaf08355fe643f11@o410888.ingest.sentry.io/5671233",
//     integrations: [new Integrations.BrowserTracing() as any],
//     environment: (process.env.NODE_ENV === 'production') ? "production" : 'development',
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });


export default ({ Component, pageProps }: AppProps) => {
    
    const meta = {
        hostname: hostname,
        title: `App AIMDirect`,
        description: 'Accelerate your business'
    }











    

      

    return (
        <>
      <Head>
        {/* NOTE REPLACE FONT WITH PERMINENT FONT */}

        <title>AIM Direct</title>
        <meta name="viewport" content="initial-scale=1, width=device-width, height=device-height, viewport-fit=cover"/>
        <meta name='application-name' content={meta.title} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content={meta.title} />
        <meta name='description' content={meta.description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='msapplication-config' content='/static/icons/browserconfig.xml' />
        <meta name='msapplication-TileColor' content='#2B5797' />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name='theme-color' content='#000000' />
                  
        <link rel='icon' type='image/png' sizes='32x32' href='/static/icons/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/static/icons/favicon-16x16.png' />
        <link rel='manifest' href='/static/manifest.json' />
        {/* <link rel='stylesheet' href='https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css' /> */}

            

        {/* <script defer dangerouslySetInnerHTML={{ __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '902388940572825');
            fbq('track', 'PageView');
        ` }} /> */}

{/* <!-- Global site tag (gtag.js) - Google Ads: 366155099 --> */}
        {/* <script defer dangerouslySetInnerHTML={{ __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '902388940572825');
            fbq('track', 'PageView');
        ` }} /> */}


            {/* <script defer src="https://www.googletagmanager.com/gtag/js?id=AW-366155099"></script>
            <script defer dangerouslySetInnerHTML={{ __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'AW-366155099');
            `}} /> */}

        
{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-9R1FF1YH62"></script>
      <script dangerouslySetInnerHTML={{ __html:`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', 'G-9R1FF1YH62');
        `}}
      /> */}



      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-213789680-2"></script>
      <script dangerouslySetInnerHTML={{ __html:`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-213789680-2');
      `}}
      />

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-213789680-1"></script>
      <script dangerouslySetInnerHTML={{ __html:`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-213789680-1');
      `}}
      />

<script dangerouslySetInnerHTML={{__html:`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2561712,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}}
    />






        {/* <!-- Hotjar Tracking Code for https://app.aimdirect.nl --> */}
        {/* <script defer dangerouslySetInnerHTML={{ __html: ` 
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2745238,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}} /> */}





      </Head>
        <Box overflowX='hidden' >
        <Script
      strategy="lazyOnload"
      src={`https://www.googletagmanager.com/gtag/js?id=G-9R1FF1YH62`}
    />

    <Script strategy="lazyOnload">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9R1FF1YH62', {
            page_path: window.location.pathname,
          });
      `}
    </Script>
            <ChakraProvider theme={customTheme} >
                {/* <AnimatePresence exitBeforeEnter > */}
                <CSSReset/>
                {/* <AnimatePresence
                    exitBeforeEnter
                    initial={false}
                    onExitComplete={() => window.scrollTo(0, 0)}
                > */}
                    <Component {...pageProps} />
                {/* </AnimatePresence> */}
                {/* </AnimatePresence> */}
            </ChakraProvider>
        </Box>

        </>
  )
}

